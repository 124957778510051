<template>
    <div class="franchisee__branch">
        <div class="franchisee__location" v-for="(item, index) of franchise" :key="`route-${index}`">
            <div class="odd" v-if="index % 2 == 0">
                <div class="franchisee__location-left">
                    <img src="/assets/IMG_2928.jpeg" alt="Franchise Location Event">
                </div>
                <div class="franchisee__location-right">
                    <h2>{{ item.location }}</h2>
                    <p>{{ item.desc }}</p>
                    <div class="button-view">
                        <router-link to="/menugolfutaar">View Menu</router-link>
                    </div>
                </div>
            </div>
            <div class="even" v-else>
                <div class="franchisee__location-left">
                    <h2>{{ item.location }}</h2>
                    <p>{{ item.desc }}</p>
                    <div class="button-view">
                        <router-link to="/menubansbari">View Menu</router-link>
                    </div>
                </div>
                <div class="franchisee__location-right">
                    <img src="/assets/IMG_2945.jpeg" alt="Franchise Location Image">
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.franchisee__branch {
    display: flex;
    flex-direction: column;
    margin: 0 45px;
    gap: 120px;
}
.franchisee__branch h2 {
    color: var(--yellow);
    font-weight: 400;
    font-size: 3.75rem;
}
.franchisee__branch .franchisee__location .odd {
    display: flex;
    justify-content: center;
    align-items: center;

}
.franchisee__branch .franchisee__location .franchisee__location-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.franchisee__branch .franchisee__location .franchisee__location-left img {
    width: 100%;
    height: 542px;
    border-radius: 10px;
}
.franchisee__branch .franchisee__location .franchisee__location-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 40px;
}
.franchisee__branch .franchisee__location .franchisee__location-right img {
    width: 100%;
    height: 542px;
    border-radius: 10px;
}
.franchisee__branch .franchisee__location .even {
    display: flex;
    justify-content: center;
    align-items: center;
}
.franchisee__branch a {
    color: var(--yellow);
    text-align: center;
    display: flex;
    justify-content: center;
    text-decoration: underline;
    font-size: 25px;
}

.franchisee__branch p {
    font-size: 20px;
    font-weight: 400;
}

@media only screen and (max-width: 1440px){
    .franchisee__branch h2 {
        font-size: 3rem;
    }
    .franchisee__branch p{
        font-size: 1.20rem;
    }
    .franchisee__branch .franchisee__location .odd .franchisee__location-left {
        width: 49%;
        height: 100%;
        display: inline-flex;
        align-items: center;
    }
    .franchisee__branch .franchisee__location .odd .franchisee__location-left img{
        height: 420px;
    }
    .franchisee__branch .franchisee__location .odd .franchisee__location-right {
        width: 49%;
        height: 100%;
    }
    .franchisee__branch .franchisee__location .even .franchisee__location-left {
        width: 49%;
        height: 100%;
    }

    .franchisee__branch .franchisee__location .even .franchisee__location-right {
        width: 49%;
        height: 100%;
    }
    .franchisee__branch .franchisee__location .even .franchisee__location-right img{
        height: 420px;
    }
}

@media only screen and (max-width: 1200px){
    .franchisee__branch h2 {
        font-size: 2.35rem;
    }

    .franchisee__branch p{
        font-size: 1.125rem;
    }
    .franchisee__branch .franchisee__location .odd .franchisee__location-left {
        width: 49%;
        height: 100%;
        display: inline-flex;
        align-items: center;
    }
    .franchisee__branch .franchisee__location .odd .franchisee__location-left img{
        height: 350px;
    }
    .franchisee__branch .franchisee__location .odd .franchisee__location-right {
        width: 49%;
        height: 100%;
    }
    .franchisee__branch .franchisee__location .even .franchisee__location-left {
        width: 49%;
        height: 100%;
    }
    .franchisee__branch .franchisee__location .even .franchisee__location-right {
        width: 49%;
        height: 100%;
    }
    .franchisee__branch .franchisee__location .even .franchisee__location-right img{
        height: 350px;
    }
}

@media only screen and (max-width: 960px){
    .franchisee__branch {
        width: 90%;
        margin: 0 auto;
    }
    .franchisee__branch h2 {
        font-size: 2rem;
    }
    .franchisee__branch p{
        font-size: 1rem;
    }
    .franchisee__branch .franchisee__location .odd .franchisee__location-left {
        width: 49%;
        height: 100%;
        display: inline-flex;
        align-items: center;
    }
    .franchisee__branch .franchisee__location .odd .franchisee__location-left img{
        height: 320px;
        width: 100%;
    }
    .franchisee__branch .franchisee__location .odd .franchisee__location-right {
        width: 49%;
        height: 100%;
    }
    
    .franchisee__branch .franchisee__location .even .franchisee__location-left {
        width: 49%;
        height: 100%;
    }
    .franchisee__branch .franchisee__location .even .franchisee__location-right {
        width: 49%;
        height: 100%;
    }
    .franchisee__branch .franchisee__location .even .franchisee__location-right img{
        height: 320px;
        width: 100%;
    }
}

@media only screen and (max-width: 640px) {
    .franchisee__branch {
        margin: 0 auto;
        gap: 25px;
    }
    .franchisee__branch h2 {
        color: var(--yellow);
        font-weight: 400;
        font-size: 1.5rem;
    }
    .franchisee__branch .franchisee__locatiom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
    }
    .franchisee__branch .franchisee__location .odd {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: -25px;
    }
    .franchisee__branch .franchisee__location .odd .franchisee__location-left {
        width: 100%;
        height: 100%;
    }
    .franchisee__branch .franchisee__location .odd .franchisee__location-left img{
        height: 320px;
        width: 100%;
    }
    .franchisee__branch .franchisee__location .odd .franchisee__location-right {
        width: 100%;
        height: 100%;
    }
    .franchisee__branch .franchisee__location .odd .franchisee__location-right img{
        height: 320px;
        width: 100%;
    }
    .franchisee__branch .franchisee__location .even {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .franchisee__branch .franchisee__location .even .franchisee__location-left {
        width: 100%;
        height: 100%;
    }
    .franchisee__branch .franchisee__location .even .franchisee__location-left img{
        height: 100%;
        width: 100%;
    }
    .franchisee__branch .franchisee__location .even .franchisee__location-right {
        width: 100%;
        height: 100%;
    }
    .franchisee__branch .franchisee__location .even .franchisee__location-right img{
        height: 320px;
        width: 100%;
    }
    .franchisee__branch a {
        font-size: 1rem;
        margin: 10px auto;
    }
    .franchisee__branch p {
        font-size: 0.865rem;
        font-weight: 400;
    }
}

@media only screen and (max-width: 480px) {
    .franchisee__branch {
        margin: 0 auto;
        gap: 25px;
    }
    .franchisee__branch h2 {
        color: var(--yellow);
        font-weight: 400;
        font-size: 1.5rem;
    }
    .franchisee__branch .franchisee__locatiom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
    }
    .franchisee__branch .franchisee__location .odd {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 25px;
    }
    .franchisee__branch .franchisee__location .odd .franchisee__location-left {
        width: 100%;
        height: 100%;
    }
    .franchisee__branch .franchisee__location .odd .franchisee__location-left img{
        height: 320px;
        width: 100%;
    }
    .franchisee__branch .franchisee__location .odd .franchisee__location-right {
        width: 100%;
        height: 100%;
    }
    .franchisee__branch .franchisee__location .odd .franchisee__location-right img{
        height: 320px;
        width: 100%;
    }
    .franchisee__branch .franchisee__location .even {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .franchisee__branch .franchisee__location .even .franchisee__location-left {
        width: 100%;
        height: 100%;
    }
    .franchisee__branch .franchisee__location .even .franchisee__location-left img{
        height: 100%;
        width: 100%;
    }
    .franchisee__branch .franchisee__location .even .franchisee__location-right {
        width: 100%;
        height: 100%;
    }
    .franchisee__branch .franchisee__location .even .franchisee__location-right img{
        height: 320px;
        width: 100%;
    }
}

@media only screen and (max-width: 375px) {
    .franchisee__branch {
        margin: 0 auto;
        gap: 25px;
    }
    .franchisee__branch h2 {
        color: var(--yellow);
        font-weight: 400;
        font-size: 1.5rem;
    }
    .franchisee__branch .franchisee__locatiom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
    }
    .franchisee__branch .franchisee__location .odd {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 25px;
    }
    .franchisee__branch .franchisee__location .odd .franchisee__location-left {
        width: 100%;
        height: 100%;
    }
    .franchisee__branch .franchisee__location .odd .franchisee__location-left img{
        height: 320px;
        width: 100%;
    }
    .franchisee__branch .franchisee__location .odd .franchisee__location-right {
        width: 100%;
        height: 100%;
    }
    .franchisee__branch .franchisee__location .odd .franchisee__location-right img{
        height: 320px;
        width: 100%;
    }
    .franchisee__branch .franchisee__location .even {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .franchisee__branch .franchisee__location .even .franchisee__location-left {
        width: 100%;
        height: 100%;
    }
    .franchisee__branch .franchisee__location .even .franchisee__location-left img{
        height: 100%;
        width: 100%;
    }
    .franchisee__branch .franchisee__location .even .franchisee__location-right {
        width: 100%;
        height: 100%;
    }
    .franchisee__branch .franchisee__location .even .franchisee__location-right img{
        height: 320px;
        width: 100%;
    }
}
</style>

<script setup>
import { ref } from 'vue';
const franchise = ref([
    {
        imgUrl: "/assets/IMG_2928.jpeg",
        location: "Golfutaar, Kathmandu",
        desc: "Brunch and Munch has expanded its franchise to Golfutaar, Kathmandu, with the same high-quality dining experience. Established in 2019, the new location offers delicious meals, a cozy ambiance, and attentive staff, making it a popular venue for different occasions."
    },
    {
        imgUrl: "https://picsum.photos/id/30/2000/3000",
        location: "Bansbari, Kathmandu",
        desc: "Brunch and Munch franchise located in Bansbari, Kathmandu, established in 2023, offering the same high-quality dining experience, with a warm ambiance and customizable menus. It has quickly gained a reputation for its delicious meals and attentive staff, making it a popular venue for various occasions.We also offer Indian Cuisines here."
    }
])
</script>
