<template>
    <div class="menu__header">
        <h1>Our Menu</h1>
        <img src="@/assets/menuheader.png" alt="">
    </div>
</template>

<style scoped>
@import "@/css/common.css";
.menu__header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    height: 200px;
    filter: blur(0.3px);
}
.menu__header h1{
    color: var(--yellow);
    font-family: 'Marcellus';
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 120.5%;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    position: absolute;
}
.menu__header img{
    width: 100%;
    height: 100%;
}

</style>

<script setup></script>
