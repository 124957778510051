<template>
    <div class="gallery__images">
        <div class="gallery__images-top container" v-for="(image, index) in state.images" :key="`count-${index}`">
            <img :src="image">
        </div>
    </div>
</template>

<style scoped>
.gallery__images {
    width: 80%;
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.container .container__top {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.container .container__top img {
    height: 100%;
    width: 100%;
}

.container .container__middle {
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.container .container__bottom {
    display: grid;
}


.gallery__images {
    display: grid;
    grid-template-columns: repeat(3, 4fr);
    gap: 20px;
    margin: 120px auto;
}

.container:nth-child(6n) {
    grid-column-start: 1;
    grid-column-end: 4;
}
.container:nth-child(6n-2) {
    grid-column-start: 1;
    grid-column-end: 3;
}

.container {
    height: 20rem;
}
.container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}
@media only screen and (max-width:1200px){
.container {
    height: 13rem;
}
}
@media only screen and (max-width:800px){
.container {
    height: 9rem;
}
}
@media only screen and (max-width:500px){
    .gallery__images{
        display: block;
    }

    .container {
        margin-bottom: 25px;
    }
}
</style>

<script setup>
import { reactive } from "vue";
const state = reactive(
    {
        images: [
            "/assets/sekuwa.jpg",
            "/assets/pasta.jpg",
            "/assets/sandwich.jpg",
            "/assets/burger.jpg",
            "/assets/greensalad.jpg",
            "/assets/items.jpg",
            "/assets/IMG_2925.jpeg",
            "/assets/IMG_2928.jpeg",
            "/assets/IMG_2929.jpeg",
            "/assets/IMG_2935.jpeg",
            "/assets/IMG_2938.jpeg",
            "/assets/IMG_2941.jpeg",
            "/assets/IMG_2945.jpeg",
            "/assets/IMG_2950.jpeg",
        ]
    }
)
</script>
