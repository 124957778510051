<template>
    <div class="about">
        <div class="about__container">
            <div class="about__left">
                <h2 class="heading">Some Word About US</h2>
                <p>Brunch and Munch is a popular restaurant located in the bustling area of Mandikhatar, Kathmandu, Nepal.
                    The restaurant prides itself on providing delicious meals to customers while also offering a great
                    ambiance for socializing with family and friends. With a slogan of "where every bite is a delight," the
                    restaurant aims to create a memorable dining experience for its customers.</p>
                <br>
                <p>Brunch and Munch is well-known for its delectable breakfast options and other mouth-watering dishes
                    served throughout the day. Whether it's a casual brunch with friends or a family dinner, the restaurant
                    provides a warm and welcoming atmosphere that is perfect for any occasion.</p>
            </div>
            <div class="about__right">
                <img src="/assets/IMG_2935.jpeg" alt="Office Pic">
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped>
.about {
    background-image: url("@/assets/aboutus.svg");
    background-size: cover;
}

.about__container {
    display: flex;
    width: 70%;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    padding: 10rem 0px;
    gap: 20px;
}

.about .about__container .about__left {
    display: flex;
    flex-direction: column;
    width: 49%;
}

.about .about__container .about__left .heading {
    font-weight: 400;
    font-size: 3.125rem;
}

.about .about__container .about__right {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
}

.about .about__container .about__right img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

@media only screen and (max-width:1450px) {

    .about .about__container .about__right {
        height: 400px;
        align-self: center;
        justify-self: center;
    }

    .about .about__container .about__left .heading {
        font-weight: 400;
        font-size: 2.75rem;
    }

    .about .about__container .about__left p {
        font-size: 1rem;
    }
}

@media only screen and (max-width:1280px) {
    .about .about__container .about__right {
        height: 350px;
    }

    .about .about__container .about__left .heading {
        font-weight: 400;
        font-size: 2.40rem;
    }

    .about .about__container .about__left p {
        font-size: 0.95rem;
    }
}

@media only screen and (max-width:1112px) {

    .about .about__container .about__right {
        height: 300px;
    }

    .about .about__container .about__left .heading {
        font-weight: 400;
        font-size: 2rem;
    }

    .about .about__container .about__left p {
        font-size: 0.85rem;
    }
}

@media only screen and (max-width:1080px) {
    .about__container {
        flex-direction: column;
        width: 100%;
        gap: 5rem;
    }

    .about__container .about__left {
        width: 90% !important;
    }
    .about .about__container .about__right {
        height: 300px;
        width: 90% !important;
    }

    .about .about__container .about__left .heading {
        font-weight: 400;
        font-size: 1.75rem;
    }

    .about .about__container .about__left p {
        font-size: 0.75rem;
    }
}

@media only screen and (max-width: 800px) {
    .about .about__container .about__right {
        height: 330px;
    }

    .about .about__container .about__left .heading {
        font-weight: 400;
        font-size: 1.5rem;
    }

    .about .about__container .about__left p {
        font-size: 0.71rem;
    }
}</style>
