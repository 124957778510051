<template>
    <div class="home__events">
        <div class="home__events__left">
            <img src="/assets/IMG_2938.jpeg" alt="" class="top">
            <img src="/assets/IMG_2921.jpeg" alt="" class="down">
        </div>
        <div class="home__events__right">
            <div class="right-top">
                <h2 class="events__right-title">Our Events</h2>
                <p class="top-subtitle">Come With Family & Feel The Joy Of Mouthwatering Food</p>
            </div>
            <div class="right-center">
                <p>Brunch and Munch is proud to announce the launch of their event hosting services. The restaurant offers
                    customizable menus, warm ambiance and attentive staff to make your family gatherings, birthdays and
                    special occasions an unforgettable experience. Book now to create lasting memories with your loved ones.
                </p>
            </div>
            <div class="right-bottom">
                <p class="bottom-book">Book Through Call</p>
                <h3 class="events__right-title">+9779803478432</h3>
            </div>
        </div>
    </div>
</template>

<style scoped>
.home__events {
    width: 70%;
    margin: 50px auto;
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
}

.home__events .home__events__left {
    width: 40%;
    height: 400px;
    position: relative;
}

.home__events .home__events__left .down {
    position: absolute;
    width: 200px;
    height: 200px;
    bottom: -50px;
    left: -84px;
}

.home__events .home__events__left img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.home__events .home__events__right {
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.home__events .home__events__right .events__right-title {
    color: var(--yellow);
    font-size: 3.125rem;
    font-weight: 400;
}

.home__events .home__events__right .top-subtitle {
    font-weight: 400;
    font-size: 1.125rem;
}

.home__events .home__events__right .right-bottom .bottom-book {
    font-size: 1.5rem;
}

@media only screen and (max-width:1240px) {

    .home__events .home__events__right .events__right-title {
        font-size: 2.5rem;
    }

}

@media only screen and (max-width:1070px) {

    .home__events {
        flex-direction: column;
        width: 100%;
        gap: 5rem;
    }

    .home__events .home__events__left {
        width: 100% !important;
        margin: 0 auto;
        aspect-ratio: 1 !important;
        display: flex;
        align-items: center;
    }

    .home__events .home__events__right {
        width: 90% !important;
        margin: 0 auto;
    }

    .home__events .home__events__left .top {
        width: 90% !important;
        aspect-ratio: 1 !important;
        margin: 0 auto;
    }

    .home__events .home__events__left .down {
       left: 10% !important;
    }

    .home__events .home__events__right .events__right-title {
        font-size: 2.5rem;
    }

    .home__events .home__events__right .top-subtitle {
        font-weight: 400;
        font-size: 1rem;
    }

    .home__events .home__events__right p {
        font-size: 0.865rem;
    }

    .home__events .home__events__right .right-bottom .bottom-book {
        font-size: 1.25rem;
    }
}

@media only screen and (max-width:855px) {
    .home__events .home__events__left .down {
        height: 150px;
        width: 150px;
        left: -40px;
        bottom: -60px;
    }

    .home__events .home__events__right .events__right-title {
        font-size: 1.75rem;
    }

    .home__events .home__events__right .top-subtitle {
        font-weight: 400;
        font-size: 0.865rem;
    }

    .home__events .home__events__right p {
        font-size: 0.765rem;
    }

    .home__events .home__events__right .right-bottom .bottom-book {
        font-size: 1.125rem;
    }
}
</style>

<script setup>
</script>
