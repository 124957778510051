<template>
    <div class="gallery__header">
        <h1>Gallery</h1>
        <img src="@/assets/galleryheader.png" alt="Gallery Page">
    </div>
</template>

<style scoped>
@import "@/css/common.css";
.gallery__header{
    width: 100%;
    height: 300px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.gallery__header h1{
    position: absolute;
    text-align: center;
    color: var(--yellow);
    font-family: 'Marcellus';
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 120.5%;
    text-align: center;
}
.gallery__header img{
    width: 100%;
    height:100%;
}

@media only screen and (max-width: 780px){
    .gallery__header{
    width: 100%;
    height: 250px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.gallery__header h1{
    position: absolute;
    text-align: center;
    color: var(--yellow);
    font-family: 'Marcellus';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
}
}

@media only screen and (max-width: 540px){
.gallery__header{
    width: 100%;
    height: 200px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.gallery__header h1{
    position: absolute;
    text-align: center;
    color: var(--yellow);
    font-family: 'Marcellus';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
}
}



</style>

<script setup>

</script>