import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import VueGtag from "vue-gtag";

createApp(App)
.use(VueGtag, {
    config: { 
    id: "GA_MEASUREMENT_ID",
    },
}, router)
.use(router)
.use(ElementPlus)
.mount('#app')
