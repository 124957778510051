<template>
    <div class="nav__container">
        <nav>
            <ul>
                <router-link :to="item.path" v-for="(item, index) in routes" :key="`route-${index}`"
                    :class="{ 'active': currentRouter === item.path }">{{ item.label }}
                </router-link>
            </ul>
        </nav>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
const routes = ref([
    {
        label: 'Home',
        path: '/',
    },
    {
        label: 'Franchise',
        path: '/franchise',
    },
    // {
    //     label: 'Events',
    //     path: '/events',
    // },
    {
        label: 'Gallery',
        path: '/gallery'
    },
])

const route = useRoute()
const currentRouter = computed(() => {
    return route.fullPath
})

</script>
<style scoped>
@import "@/css/common.css";
@import "@/css/navtop/navtop.css";
</style>
