<template>
<div class="special">
    <div class="special__title">
        <h1 class="heading">Weekly Special</h1>
        <!-- <p class="subtitle">Come with family & feel the joy of mouthwatering food</p> -->
    </div>
        <div class="special__content">
            <div class="content__menu" v-for="input in data">
                <div class="imagewrapper">
                    <img class="image-offer" :src=" '/assets/' + input.imgUrl">
                </div>
                <h2 class="menu-name">{{ input.name }}</h2>
                <router-link class="menu__name-name" to="/menubansbari">{{ input.menu }}</router-link>
            </div>
                <div class="menu-bg"></div>
        </div>
</div>
</template>

<style scoped>
.special .special__content .content__menu .imagewrapper{
    width: 200px;
    height: 200px;
    z-index: 3;
}
.special .special__content .content__menu .imagewrapper .image-offer{
    height: 100%;
    width: 100%;
    object-fit:contain;
}
.special .menu__name-name{
    font-weight: 400;
    font-size: 20px;
    color: var(--yellow);
}
.special{
    background: #461111;
    position: relative;
    backdrop-filter: blur(2px);
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 10rem 0;
}
.special .special__title{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 0;
}
.special .special__title .heading{
    font-family: 'Marcellus';
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    text-align: center;
    color: #FFC220;
}

.special .special__content {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    color: #fff;
    position: relative;
    margin-bottom: 25px;
    width: 90%;
    margin: 0 auto;
    padding: 25px 0;
    align-items: center;
    gap: 20px;
}

.special .special__content .content__menu {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 3;
}
.special .special__content .content__menu img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.special .special__content .content-menu .menu-name {
    font-weight: 400;
    font-size: 30px;
}
.special .special__content .content-menu .menu__name-name{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color: #FFC220;
}
.menu-bg {
    position: absolute;
    background: url("@/assets/brickwall.svg") repeat fixed center;
    min-width: 100%;
    min-height: 100%;
    z-index: 1;
    filter: blur(1px);
    margin: 0 auto;
    left: 0px !important;

}

@media only screen and (max-width:1440px){
    .special .special__content .content__menu .imagewrapper{
        width: 180px;
        height: 180px;
        object-fit: contain;
    }

    .menu-bg{
        min-height: 80%;
    }
}
@media only screen and (max-width:1048px){
    .special .special__content .content__menu .imagewrapper{
        width: 130px;
        height: 130px;
        align-self: center;
        justify-self: center;
    }
    .special img{
        height: 130px;
        width: 130px;
    }
}
@media only screen and (max-width:750px){
    .special .special__content .content__menu .imagewrapper{
        width: 100%;
        height: 100%;
        justify-self: center;
        align-self: center;
    }
    .special .special__content .content__menu .imagewrapper img{
        height: 90px;
    }

    .menu-bg{
        min-height: 60%;
    }
}
</style>

<script setup>
import {ref} from 'vue';
const data = ref([
    {
        imgUrl:"sandwich.jpg",
        name:"Breakfast",
        menu:"View Menu",
    },
    {
        imgUrl:"coffee.jpg",
        name:"Drinks",
        menu:"View Menu"
    },
    {
        imgUrl:"items.jpg",
        name:"Appetizers",
        menu:"View Menu"
    },
    {
        imgUrl:"sekuwa.jpg",
        name:"Non-Veg",
        menu:"View Menu"
    },
    {
        imgUrl:"butterchicken.png",
        name:"Indian-Dish",
        menu:"View Menu"
    }
])
</script>
