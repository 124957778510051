<template>
    <el-carousel :interval="4000" arrow="always" height="100%">
        <el-carousel-item v-for="item in images" :key="item.id">
            <img :src="item.img">
        </el-carousel-item>
        <div class="mask"></div>
    </el-carousel>
</template>

<script setup>
import { ref } from "vue";
import "vue3-carousel/dist/carousel.css";

const images = ref([
    { id: 1, img: "assets/sandwich.jpg"},
    { id: 2, img: "assets/sekuwa.jpg" },
    { id: 3, img: "assets/pasta.jpg" },
    { id: 4, img: "assets/greensalad.jpg" },
]);
</script>

<style>
.el-carousel__item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
} */

.mask {
    position: absolute;
    width: 100%;
    min-height: 100%;
    filter: blur(400px);
    background-color: #0000006b;
    top: 0;
}
</style>
