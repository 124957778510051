<template>
    <div class="homepage__menu">
        <div class="homepage__menu-header">
            <p class="desc">Special Selection</p>
            <h2>Menu Golfutar</h2>
        </div>
        <div class="homepage__menu-content">
            <div class="homepage__menu-container" v-for="(item, index) in data">
                <h2 class="menu-title">{{ index }}</h2>
                <div class="menu-container__wrapper">
                    <div class="menu-contents" v-for="i in item" v-if="index !== 'HARD DRINKS'">
                        <div class="right">
                            <div class="right__name">
                                <h3>{{ i.name }}</h3>
                                <p class="colon">&#8212;</p>
                                <p class="price">{{ i.price }}</p>
                            </div>
                            <p class="descript">{{ i.desc }}</p>
                        </div>
                    </div>
                    <div class="menu-contents" v-for="i in item" v-else>
                        <div class="hardrinks">
                            <h3>{{ i.name }}</h3>
                            <table>
                                <thead class="hardrinks__top">
                                    <td>30ML</td>
                                    <td>60ML</td>
                                    <td>90ML</td>
                                    <td>Qtr</td>
                                    <td>Half</td>
                                    <td>Full</td>
                                </thead>
                                <div class="harddrinks__bottom">
                                    <tbody class="table__data" v-for="price in i.price">
                                        <td>Rs {{ price }}</td>
                                    </tbody>
                                </div>
                                
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="coffeeimgcontainer">
                <img src="@/assets/offercoffee.png" alt="Coffee Logo" class="Coffee">
            </div>
        </div>
    </div>    
</template>

<style scoped>
@import "@/css/menu.css";
.homepage__menu{
    background: url("@/assets/menu-bg.svg") repeat;
    object-fit: cover;
    margin-bottom: 80px;
}
</style>

<script setup>

import { ref } from 'vue';

const data = ref({
    "BREAKFAST": [
        {
            name: "PLain Toast",
            img: "https://picsum.photos/id/237/200/300",price: "Rs. 100",
            desc: "Plain toast is a simple breakfast item made by toasting bread until golden brown, often served with butter or jam.",
        },
        {
            name: "Butter Toast",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 140",
            desc: "Butter toast is a classic breakfast item made by toasting bread until golden brown and spreading it with butter, adding a rich and creamy flavor to the crispy toast."
        },
        {
            name: "French Toast",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 180",
            desc: "French toast is a breakfast dish made by dipping bread in a mixture of eggs, milk, and cinnamon, then frying until golden brown and often served with syrup or fruit."
        },
        {
            name: "Cheese Toast",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",
            desc: "Cheese toast is a savory breakfast or snack item made by toasting bread with melted cheese on top, creating a warm and gooey treat."
        },
        {
            name: "Omelette (2 Eggs)",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 160",
            desc: "An omelette is a breakfast dish made by whisking eggs and filling them with various ingredients."
        },
        {
            name: "Cheese Omelette (2 Eggs) ",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",
            desc: "A cheese omelette is a classic breakfast dish made by whisking eggs, filling them with cheese, and cooking them in a pan until set, resulting in a warm and gooey meal."
        },
        {
            name: "Boiled Egg (2PcS)",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 140",
            desc: "A boiled egg is an egg that has been cooked by immersing it in boiling water until the egg white and yolk have set."
        },
        {
            name: "Scrambled Egg",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 160",
            desc: "Scrambled eggs are a breakfast dish made by whisking eggs and cooking them in a pan with butter or oil, stirring constantly until the eggs are light, fluffy, and fully cooked."
        },
    ],
    
    "HOUSE SPECIAL BREAKFAST": [
        {
            name: "2 Eggs, Sausage, Home Made Potato, Bread With Tea Or Coffee",
            desc: "A house special breakfast typically consists of a combination of breakfast foods like eggs, bacon, sausage, toast.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 500",
        }
    ],
    "SALAD": [
        {
            name: "Seasonal Veg Salad",
            desc: "A seasonal veg salad is a fresh and healthy dish made with vegetables that are in season, dressed with a flavorful dressing and toppings.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",
        },
        {
            name: "Seasonal Chicken Salad",
            desc: "A seasonal chicken salad is a healthy and flavorful dish made with fresh vegetables and chicken, dressed with a tasty dressing and toppings.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 300",
        },
        {
            name: "Green Salad",
            desc: "A green salad is a fresh and nutritious dish made with a variety of greens, dressed with a flavorful dressing and topped with vegetables.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 300",
        }
    ],
    "SOUP": [
        {
            name: "Veg Soup",
            desc: "Vegetable soup is a nutritious dish made by cooking a variety of vegetables in broth or stock until tender and blending them into a smooth, comforting soup.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 150",
        },
        {
            name: "Hot & Sour Soup",
            desc: "Hot and sour soup is a Chinese-inspired soup that balances spicy and sour flavors with ingredients like mushrooms, tofu, and vinegar.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 250",
        },
        {
            name: "Chicken Soup",
            desc: "Chicken soup is a classic comfort food made by simmering chicken, vegetables, and herbs in broth, often enjoyed as a nourishing meal or as a remedy for colds and flu.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",
        },
        {
            name: "Chicken Mushroom Soup",
            desc: "Chicken mushroom soup is a flavorful soup made with chicken, mushrooms, and a creamy broth, often seasoned with herbs and spices for added depth of flavor.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 250",
        },
    ],
    "Sandwich":[
        {
            name: "Veg Sandwich",
            desc: "Fresh bread filled with vegetables, cheese, and spread, grilled or cold.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",   
        },
        {
            name: "Egg Sandwich",
            desc: "Egg sandwich is a breakfast staple, made with bread, eggs, and cheese or meat, often served with condiments.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 250",   
        },
        {
            name: "Grilled Chicken Sandwich",
            desc: "Grilled chicken sandwich is a hearty meal, made with grilled chicken, bread, cheese, and toppings like lettuce, tomato, or bacon.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 350",   
        },
        {
            name: "Chicken Sandwich",
            desc: "Chicken sandwich is a satisfying meal, made with bread, chicken, and toppings like cheese, lettuce, or mayo.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 300",   
        },
        {
            name: "Tuna Sandwich",
            desc: "Tuna sandwich is a classic, made with bread, tuna, and mayo, often with added celery or pickles for crunch.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 350",   
        },
        {
            name: "Tuna Melt SandWich",
            desc: "Tuna melt sandwich is a warm and cheesy version, made with bread, tuna salad, and melted cheese, often broiled for a crispy finish.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 400",   
        },
        {
            name: "HOT Potato Sandwich",
            desc: "Hot potato sandwich is a comfort food, made with mashed potatoes, bread, and toppings like bacon or cheese, often toasted or grilled.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 250",   
        },
    ],
    "Burger":[
        {
            name: "Veg Burger",
            desc: "Veg burger is a meat-free option, made with a vegetable patty, bread, and toppings like lettuce, tomato, or cheese.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",   
        },
        {
            name: "Egg Burger",
            desc: "Egg burger is a tasty option, made with a fried or boiled egg, bread, and toppings like bacon, cheese, or lettuce.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 250",   
        },
        {
            name: "Grilled Chicken Burger",
            desc: "Grilled chicken burger is a filling meal, made with a grilled chicken patty, bread, and toppings like lettuce, tomato, or cheese.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 350",   
        },
        {
            name: "Chicken Burger",
            desc: "Chicken burger is a popular option, made with a chicken patty, bread, and toppings like lettuce, tomato, or mayo.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 300",   
        },
        {
            name: "Crispy Chicken Burger",
            desc: "Crispy chicken burger is a satisfying meal, made with a breaded chicken patty, bread, and toppings like lettuce, tomato, or cheese.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 350",   
        },
        {
            name: "Double Patty Chicken Burger",
            desc: "Double patty chicken burger is a hearty meal, made with two chicken patties, bread, and toppings like lettuce, tomato, or cheese.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 450",   
        },
    ],

    // Price Remaining
    "Pizza": [ 
    {
            name: "Veg Pizza",
            desc: "Veg pizza is a delicious classic, made with a vegetarian sauce, cheese, and toppings like vegetables or mushrooms.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 380",   
    },
    {
            name: "Mushroom Pizza",
            desc: "Mushroom pizza is a flavorful choice, made with mushroom sauce or slices, cheese, and other toppings like onions or peppers.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 400",   
    },
    {
            name: "Cheese Pizza",
            desc: "Cheese pizza is a timeless favorite, made with tomato sauce, cheese, and often additional toppings like pepperoni or vegetables.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 380",   
    },
    {
            name: "Chicken Pizza",
            desc: "Chicken pizza is a satisfying option, made with chicken, tomato sauce, cheese, and toppings like onions, peppers, or barbecue sauce.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 450",   
    },
    {
            name: "Mixed Pizza",
            desc: "Pizza with a variety of toppings, including meats and vegetables.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",   
    },
    ],

    "PASTA": [
    {
            name: "Pasta With Red Sauce (Veg)",
            desc: "Tomato-based pasta with garlic, onions, and herbs.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 300",   
        },
        {
            name: "Pasta With Red Sauce (Chicken)",
            desc: "Chicken added to classic pasta with tomato sauce.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 350",   
        },
    ],
    "MOMO":[
        {
            name: "Veg Steam Momo",
            desc: "Steamed veg dumplings with spicy dipping sauce.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 140",   
        },
        {
            name: "Chicken Steam Momo",
            desc: "Steamed veg dumplings with spicy dipping sauce.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 160",   
        },
        {
            name: "Buff Steam Momo",
            desc: "Steamed buffalo dumplings with spicy tomato-garlic dipping sauce.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 150",   
        },
        // todo
        {
            name: "Veg Fry Momo",
            desc: "Fried vegetable dumplings with tangy soy dipping sauce.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 150",   
        },
        {
            name: "Chicken Fry Momo",
            desc: "Nepalese snack with seasoned chicken filling, deep-fried.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 170",   
        },
        {
            name: "Buff Fry Momo",
            desc: "Nepalese snack with seasoned buffalo filling, deep-fried.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 160",   
        },
        {
            name: "Veg 'C' Momo",
            desc: "Spicy Nepalese dumpling with veggie filling, steamed.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 160",   
        },
        {
            name: "Chicken 'C' Momo",
            desc: "Spicy Nepalese dumpling with ground chicken filling.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 180",   
        },
        {
            name: "Buff 'C' Momo",
            desc: "Spicy Nepalese dumpling with ground buff filling.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 170",   
        },
        {
            name: "Veg Jhol Momo",
            desc: "Vegetarian Nepalese dumpling served in a savory soup.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 160",   
        },
        {
            name: "Chicken Jhol Momo",
            desc: "Nepalese dumpling with chicken filling in savory soup.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 180",   
        },
        {
            name: "Buff Jhol Momo",
            desc: "Nepalese dumpling with buff filling in savory soup.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 170",   
        },
        {
            name: "Veg Kothey Momo",
            desc: "Pan-fried vegetarian Nepalese dumpling with vegetable filling.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 170",   
        },
        {
            name: "Chicken Kothey Momo",
            desc: "Pan-fried Nepalese dumpling with chicken filling and spices.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",   
        },
        {
            name: "Buff Kothey Momo",
            desc: "Pan-fried Nepalese dumpling with buff filling and spices.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 190",   
        },
        // todo above
    ],
    "CHOWMEIN": [
        {
            name: "Veg Chowmein",
            desc: "Stir-fried vegetarian noodles with vegetables and sauce.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 140",   
        },
        {
            name: "Egg Chowmein",
            desc: "Stir-fried noodles with eggs, vegetables and sauce.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 150",   
        },
        {
            name: "Chicken Chowmein",
            desc: "Stir-fried noodles with chicken, vegetables and sauce.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 170",   
        },
        {
            name: "Buff Chowmein",
            desc: "Stir-fried noodles with buff, vegetables and sauce.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 160",   
        },
        {
            name: "Pork Chowmein",
            desc: "Stir-fried noodles with pork, vegetables and sauce.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 190",   
        },
        {
            name: "Mixed Chowmein",
            desc: "Stir-fried noodles with mixed vegetables and a variety of proteins.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 220",   
        },
    ],
    "THUKPA": [
        {
            name: "Veg Thukpa",
            desc: "Vegetarian Tibetan soup with noodles and vegetables.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 140",   
        },
        {
            name: "Egg Thukpa",
            desc: "Tibetan soup with noodles, vegetables, and boiled egg.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 150",   
        },
        {
            name: "Chicken Thukpa",
            desc: "Tibetan soup with noodles, vegetables, and chicken pieces.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 170",   
        },
        {
            name: "Buff Thukpa",
            desc: "Tibetan soup with noodles, vegetables, and buff pieces.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 160",   
        },
        {
            name: "Pork Thukpa",
            desc: "Tibetan soup with noodles, vegetables, and pork pieces.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 190",   
        },
        {
            name: "Mixed Thukpa",
            desc: "Tibetan soup with noodles, vegetables, and mixed proteins.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 220",   
        },
    ],
    "FRIED RICE" : [
        {
            name: "Veg Fried Rice",
            desc: "Vegetarian stir-fried rice with mixed vegetables.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 140",   
        },
        {
            name: "Egg Fried Rice",
            desc: "Stir-fried rice with egg and mixed vegetables.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 150",   
        },
        {
            name: "Chicken Fried Rice",
            desc: "Stir-fried rice with chicken and mixed vegetables.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 170",   
        },
        {
            name: "Buff Fried Rice",
            desc: "Stir-fried rice with buff and mixed vegetables.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 160",   
        },
        {
            name: "Pork Fried Rice",
            desc: "Stir-fried rice with pork and mixed vegetables.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 190",   
        },
        {
            name: "Mixed Fried Rice",
            desc: "Stir-fried rice with mixed vegetables and a variety of proteins.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 220",   
        },
    ],
    "JHANEKO SEKUWA SET":[
        {
            name: "Chicken Sekuwa Set",
            desc: "Grilled and spiced chicken dish served with sides.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 350",      
        },
        {
            name: "Pork Sekuwa Set",
            desc: "Grilled and spiced pork dish served with sides.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 350",      
        },
    ],
    "Sizzler": [
        {
            name:"Chicken Sizzler",
            desc: "Sizzling platter with grilled chicken, vegetables, and sauce.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 550",   
        },
        {
            name:"Fish Sizzler",
            desc: "Sizzling platter with grilled fish, vegetables, and sauce.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 600",   
        },
        {
            name:"Pork Sizzler",
            desc: "Sizzling platter with grilled pork, vegetables, and sauce.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 550",   
        },
    ],
    "RICE BOWL":[
        {
            name: "Chicken Rice Bowl",
            desc: "Bowl of rice topped with chicken, vegetables, and sauce.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 350",   
        },
        {
            name: "Pork Cabbage Rice Bowl",
            desc: "Bowl of rice topped with pork, vegetables, and sauce.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 300",   
        },
    ],
    "ROTI ROLL": [
        {
            name:"Veg Roti Roll",
            desc: "Whole wheat roll filled with vegetables and sauce.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 180",   
        },
        {
            name:"Chicken Roti Roll",
            desc: "Chicken-filled whole wheat roll with vegetables and sauce.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 220",   
        },
        {
            name:"Buff Roti Roll",
            desc: "Buff-filled whole wheat roll with vegetables and sauce.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",   
        },
        {
            name:"Egg Roti Roll",
            desc: "Egg-filled whole wheat roll with vegetables and sauce.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",   
        },
    ],
    "SNACKS(VEG)":[
        {
            name:"Dry Pappad",
            desc: "Crispy, thin, and seasoned Indian flatbread.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 100",   
        },
        {
            name:"FRy Pappad",
            desc: "Crispy, thin, and seasoned Indian flatbread, deep-fried to perfection.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 120",   
        },
        {
            name:"French Fries",
            desc: "Thinly sliced and deep-fried potato sticks.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",   
        },
        {
            name:"Aloo Jeera",
            desc: "Indian side dish made with potatoes and cumin seeds.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 150",   
        },
        {
            name:"Veg Pakoda",
            desc: "Deep-fried vegetarian fritters made with gram flour and spices.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",   
        },
        {
            name:"Potato Chilly",
            desc: "Deep-fried potato cubes coated with a spicy chili sauce.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 250",   
        },
        {
            name:"Paneer Chilly",
            desc: "Fried paneer cubes coated in a spicy chili sauce.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 300",   
        },
        {
            name:"Paneer Pakoda",
            desc: "Deep-fried fritters made with paneer, gram flour, and spices.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 250",   
        },
        {
            name:"Peanut Sandeko",
            desc: "Peanuts seasoned with a mixture of spices in Nepali cuisine.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",   
        },
        {
            name:"Aloo Sandeko",
            desc: "Nepali dish of spiced, pan-fried crispy boiled potatoes.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 150",   
        },
        {
            name:"Bhatmas Sandeko",
            desc: "Nepali snack of roasted soybeans with spiced seasoning, crunchy texture.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",   
        },
        {
            name:"Waiwai Sandeko",
            desc: "Nepali snack made with spiced Wai Wai noodles.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 150",   
        },
    ],
    "SNACKS(NON VEG)": [
        {
            name:"Fish & Chips",
            desc: "British dish of fried fish and potatoes.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 450",   
        },
        {
            name:"Fish Chilly",
            desc: "Spicy Indo-Chinese dish of stir-fried fish.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 400",   
        },
        {
            name:"Buff Sausage Chilly",
            desc: "Spicy Nepali dish of stir-fried buffalo sausages.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",   
        },
        {
            name:"Buff Chilly",
            desc: "Spicy Nepali dish of stir-fried buffalo meat.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 300",   
        },
        {
            name:"Buff Choila",
            desc: "Spicy Nepali dish of grilled marinated buffalo meat.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 300",   
        },
        {
            name:"Pork Chilly",
            desc: "Spicy dish of stir-fried pork with seasoning.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 300",   
        },
        {
            name:"Boiled Pork",
            desc: "Pork boiled in seasoned water or broth.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 300",   
        },
        {
            name:"Chicken Sausage Chilly",
            desc: "Spicy stir-fry dish made with chicken sausages and chili peppers.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 300",   
        },
        {
            name:"Chicken Chilly BoneLess",
            desc: "Spicy Indo-Chinese dish of boneless chicken stir-fry with chili peppers.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 350",   
        },
        {
            name:"Chicken Sandeko",
            desc: "Nepali-style spicy chicken snack made with seasoned spices and meat.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 300",   
        },
        {
            name:"Chicken Wings (3PcS)",
            desc: "Chicken wings are a popular appetizer or snack, typically deep-fried or baked and coated with a spicy or sweet sauce.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 350",   
        },
        {
            name:"Chicken Pakoda",
            desc: "Spicy Indian snack made by deep-frying chicken fritters.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 400",   
        },
    ],

    // Drinks Below
    "ICED TEA": [
        {
            name:"Lemon Iced Tea",
            desc: "Iced tea infused with lemon for a refreshing drink.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",   
        },
        {
            name:"Peach Iced Tea",
            desc: "Iced tea flavored with peach for a sweet refreshment.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",   
        },
        {
            name:"Blended Lemonade",
            desc: "Lemonade made by blending lemon juice, sugar, and ice.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 220",   
        },
        {
            name:"Blended Mint Lemonade",
            desc: "Lemonade made by blending mint, lemon juice, sugar, and ice.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 230",   
        },
    ],
    "MILKSHAKE":[
        {
            name:"Vanilla",
            desc: "Classic milkshake made with vanilla ice cream and milk.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",   
        },
        {
            name:"Chocolate",
            desc: "Milkshake made with chocolate ice cream and milk.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",   
        },
        {
            name:"Strawberry",
            desc: "Milkshake made with strawberry ice cream and milk.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",   
        },
    ],
    "COLD DRINKS":[
        {
            name:"Coke/Fanta/Sprite",
            desc: "Carbonated soft drink available in Coke, Fanta, or Sprite.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 90",   
        },
        {
            name:"Pepsi/DeW",
            desc: "Carbonated soft drink available in Pepsi or Mountain Dew.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 90",   
        },
        {
            name:"Slice",
            desc: "Carbonated fruit-flavored soft drink sold under the brand Slice.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 100",   
        },
        {
            name:"Fresh Lime Soda",
            desc: "Refreshing drink made with lime juice, soda, and sugar.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 100",   
        },
        {
            name:"Mineral Water",
            desc: "Bottled water containing minerals for added health benefits.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 30",   
        },
    ],
    "COLD Coffee":[
        {
            name:"Iced Americano",
            desc: "Espresso-based coffee served over ice for a refreshing beverage.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 180",   
        },
        {
            name:"Iced Flavor Americano",
            desc: "Flavored iced coffee made with espresso and served over ice.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",   
        },
        {
            name:"Iced Latte/Cappucino",
            desc: "Espresso-based coffee mixed with milk and served over ice.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",   
        },
        {
            name:"Iced Moccha",
            desc: "Iced coffee drink made with chocolate and espresso.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 220",   
        },
    ],
    "LASSI": [
        {
            name:"Sweet/Plain/Salt Lassi",
            desc: "Lassi varieties available in sweet, plain, or salted flavors.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 180",   
        },
        {
            name:"Vanilla Lassi",
            desc: "Lassi flavored with vanilla for a sweet and creamy drink.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",   
        },
        {
            name:"Strawberry Lassi",
            desc: "Lassi made with fresh strawberries for a fruity twist.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",   
        },
        {
            name:"Chocolate Lassi",
            desc: "Lassi flavored with chocolate for a rich and indulgent drink.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",   
        },
    ],
    "HOT BEVERAGE" : [
        {
            name:"Hot Chocolate",
            desc: "Hot beverage made with melted chocolate and milk or cream.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 250",   
        },
        {
            name:"Hot Lemon",
            desc: "Hot beverage made with lemon juice, water.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 60",   
        },
        {
            name:"Hot Lemon With Honey",
            desc: "Hot beverage made with lemon juice, water, and honey.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 180",   
        },
        {
            name:"Black Tea",
            desc: "Hot beverage made by steeping black tea leaves in water.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 40",   
        },
        {
            name:"Lemon Tea",
            desc: "Tea made with black tea, lemon juice, and honey.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 50",   
        },
        {
            name:"Milk Tea",
            desc: "Hot beverage made with black tea and milk.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 60",   
        },
    ],
    "FRAPPUCCINO (BLENDED COLD Coffee)" : [
        {
            name:"Strawberry Frappe (Without Coffee)",
            desc: "Cold drink made with blended strawberries, milk, and ice.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 280",   
        },
        {
            name:"Mocha Frappe",
            desc: "Cold coffee drink made with chocolate, espresso, and ice.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 300",   
        },
        {
            name:"Caramel Frappe",
            desc: "Cold coffee drink made with caramel, milk, and ice.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 300",   
        },
        {
            name:"Strawberry Frappe",
            desc: "Cold coffee drink made with blended strawberries, milk, and ice.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 300",   
        },
        {
            name:"VaniLLa Frappe",
            desc: "Cold coffee drink made with blended vanilla, milk, and ice.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 300",   
        },
        {
            name:"Coffee Frappe",
            desc: "Cold coffee drink made with espresso, milk, and ice.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 300",   
        },
        {
            name:"Oreo Frappe",
            desc: "Cold coffee drink made with oreo cookies, milk, and ice.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 350",   
        },
    ],
    "BUBBLE TEA": [
        {
            name:"Vanilla Blue",
            desc: "Cold tea drink with vanilla and tapioca pearls.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",   
        },
        {
            name:"Mango",
            desc: "Cold tea drink with mango flavor and tapioca pearls.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",   
        },
        {
            name:"Blueberry",
            desc: "Cold tea drink with blueberry flavor and tapioca pearls.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",   
        },
        {
            name:"Yam/Keladi",
            desc: "Cold tea drink with yam/keladi flavor and tapioca pearls.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",   
        },
        {
            name:"Honeydew",
            desc: "Cold tea drink with honeydew flavor and tapioca pearls.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",   
        },
        {
            name:"Strawberry",
            desc: "Cold tea drink with strawberry flavor and tapioca pearls.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 200",   
        },
    ],
    "HUKKA": [
        {
            name:"Hukka",
            desc: "Flavored tobacco smoked through a water pipe.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 350",   
        },
        {
            name:"Extra CoiL",
            desc: "Coil used in hukka",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 50",   
        },
    ],
    "BEER": [
        {
            name:"Tuborg",
            desc: "Refreshing light lager beer from Denmark, Tuborg.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 550",   
        },
        {
            name:"BaraSinghe",
            desc: "This beer boasts flowery, woodsy, and spicy scents since it was made with aromatic hops and imported German malted barley.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 550",   
        },
        {
            name:"Carlsberg",
            desc: "Carlsberg beer: a refreshing and crisp lager.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 600",   
        },
        {
            name:"Budwiser",
            desc: "Budweiser beer, brewed using the finest ingredients.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 550",   
        },
        {
            name:"Gorkha Strong",
            desc: "Strong and smooth Nepali beer, Gorkha Strong.",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: "Rs. 500",   
        },
    ],
    "HARD DRINKS": [
        {
            name:"Ruslan Vodka",
            img: "https://picsum.photos/seed/picsum/200/300",
            price: [90, 160, 390, 625, 1250, 2500]
        },
        {
            name:"8848 Vodka",
            desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Expedita, necessitatibus porro repudiandae aliquam quaerat incidunt earum repellat voluptates sunt, hic assumenda aliquid laborum, dolores explicabo corporis et numquam quasi voluptatem.",img: "https://picsum.photos/seed/picsum/200/300",
            quantity:["30ml", "60ml", "90ml", "Qtr.", "Half", "Full"],
            price: [150, 250, 390, 770, 1550, 3100]  
        },
        {
            name:"Seto Bagh",
            desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Expedita, necessitatibus porro repudiandae aliquam quaerat incidunt earum repellat voluptates sunt, hic assumenda aliquid laborum, dolores explicabo corporis et numquam quasi voluptatem.",img: "https://picsum.photos/seed/picsum/200/300",
            quantity:["30ml", "60ml", "90ml", "Qtr.", "Half", "Full"],
            price: [150, 250, 390, 770, 1550, 3100] 
        },
        {
            name:"Khukuri Rum",
            desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Expedita, necessitatibus porro repudiandae aliquam quaerat incidunt earum repellat voluptates sunt, hic assumenda aliquid laborum, dolores explicabo corporis et numquam quasi voluptatem.",img: "https://picsum.photos/seed/picsum/200/300",
            quantity:["30ml", "60ml", "90ml", "Qtr.", "Half", "Full"],
            price: [150, 250, 400, 800, 1600, 3200]
        },
        {
            name:"Signature Green",
            desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Expedita, necessitatibus porro repudiandae aliquam quaerat incidunt earum repellat voluptates sunt, hic assumenda aliquid laborum, dolores explicabo corporis et numquam quasi voluptatem.",img: "https://picsum.photos/seed/picsum/200/300",
            quantity:["30ml", "60ml", "90ml", "Qtr.", "Half", "Full"],
            price: [150, 250,350, 700, 1400, 2800]
        },
        {
            name:"Old Durbar",
            desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Expedita, necessitatibus porro repudiandae aliquam quaerat incidunt earum repellat voluptates sunt, hic assumenda aliquid laborum, dolores explicabo corporis et numquam quasi voluptatem.",img: "https://picsum.photos/seed/picsum/200/300",
            quantity:["30ml", "60ml", "90ml", "Qtr.", "Half", "Full"],
            price: [150, 300, 600, 1200, 2400, 4800]  
        },
        {
            name:"Black Chemni",
            desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Expedita, necessitatibus porro repudiandae aliquam quaerat incidunt earum repellat voluptates sunt, hic assumenda aliquid laborum, dolores explicabo corporis et numquam quasi voluptatem.",img: "https://picsum.photos/seed/picsum/200/300",
            quantity:["30ml", "60ml", "90ml", "Qtr.", "Half", "Full"],
            price: [165, 330, 660, 1325, 2650, 5300]   
        },
    ]
})
</script>