<template>
    <div class="footer">
        <div class="footer__logo">
            <img src="@/assets/brunchnmunchfooter.png" alt="Logo">
        </div>
        <div class="footer__info">
            <p><a href="https://www.google.com/maps/place/27.7457082,85.3474482" target="_blank">Golfutaar, Kathmandu, Nepal.</a></p>
            <a href="mailto:brunch.munch1@gmail.com">brunch.munch1@gmail.com</a>
        </div>
        <div class="footer__contact">
            <p><a class="call" href="tel:+9779803478432">+9779803478432</a></p>
        </div>
        <div class="footer__social">
            <a class="fb" href="https://www.facebook.com/BandM/"></a>
            <a class="insta" href="https://www.instagram.com/brunchandmunchcafe/"></a>
        </div>
    </div>
</template>

<style scoped>
.footer{
    background: linear-gradient(0deg, rgba(0, 0, 0,0), rgba(0, 0, 0,0)), url("@/assets/footercoffee.png");
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: 594px;
}
.footer .footer__logo{
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer .footer__logo img{
    position: relative;
    top: -27px;
}
.footer .footer__info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    gap: 20px;
}
.footer .footer__info a{
    color: white;
    display: flex;
    justify-content: center;
}

.footer .footer__contact{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.footer .footer__contact p .call{
    color: #fff;
}
.footer .footer__social{
    display: flex;
    justify-content: center;
    gap: 40px;
}

.footer__social .insta {
    background: url("@/assets/insta.svg") no-repeat;
    width: 50px;
    height: 50px;
    transition: all 0.4s ease-in-out;
}

.footer__social .insta:hover {
    background: url("@/assets/insta-color.svg") no-repeat;
    width: 50px;
    height: 50px;
}

.footer__social .fb {
    background: url("@/assets/fb.svg") no-repeat;
    width: 50px;
    height: 50px;
    transition: all 0.4s ease-in-out;
}

.footer__social .fb:hover {
    background: url("@/assets/fb-color.svg") no-repeat;
    width: 50px;
    height: 50px;
}

@media only screen and (max-width: 1230px){
    .footer{
        height: 480px;
        margin-top: 60px;
    }

    .footer .footer__logo{
        position: relative;
        margin-top: 40px;
        height: 150px;
        width: 150px;
        left: 42%;
    }
}
@media only screen and (max-width: 745px){
    .footer{
        height: 480px;
    }
    .footer .footer__logo{
        left: 35%;
        height:32%;
        width: 26%;
    }
    .footer .footer__logo img{
        height: 100%;
        width: 100%;
    }
}

@media only screen and (max-width: 630px){
    .footer{
        height: 450px;
    }
    .footer .footer__logo{
        left: 32%;
        height: 35%;
        width: 29%;
    }
    .footer .footer__logo img{
        height: 100%;
        width: 100%;
    }
}
@media only screen and (max-width: 480px){
    .footer{
        height: 450px;
        object-fit: cover;
    }
    .footer .footer__logo{
        left: 36%;
        height: 30%;
        width: 25%;
    }
    .footer .footer__logo img{
        width: 100%;
        height: 100%;
    }
}
@media only screen and (max-width: 420px){
    .footer{
        height: 400px;
        object-fit: cover;
    }
    .footer .footer__logo{
        left: 35%;
        height: 31%;
        width: 28%;
        top: 27px;
    }
    .footer .footer__logo img{
        width: 100%;
        height: 100%;
    }
}
</style>

<script setup>

</script>
