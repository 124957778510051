<template>
    <div class="menu__centerimg">
        <div class="menu__centerimg-image">
            <img src="@/assets/menucenter.png" alt="Masala Pic">
        </div>
    </div>
</template>

<script setup></script>

<style scoped>
.menu__centerimg{
    display: flex;
    background-image: url("./centerimg.vue") center no-repeat fixed;
    width: 90%;
    height: 500px;
    margin: 0 auto;
}
.menu__centerimg .menu__centerimg-image{
    width: 90%;
    height: 350px;
    display: flex;
    align-self: center;
    justify-content: center;
}
@media only screen and (max-width:1766px){
    .menu__centerimg{
    display: flex;
    background-image: url("./centerimg.vue") center no-repeat fixed;
    width: 90%;
    height: 450px;
    margin: 0 auto;
}
.menu__centerimg .menu__centerimg-image{
    width: 90%;
    height: 300px;
    display: flex;
    align-self: center;
}
}

@media only screen and (max-width:1585px){
    .menu__centerimg{
    display: flex;
    background-image: url("./centerimg.vue") center no-repeat fixed;
    width: 90%;
    height: 400px;
    margin: 0 auto;
}
.menu__centerimg .menu__centerimg-image{
    width: 90%;
    height: 250px;
    display: flex;
    align-self: center;
}
}
@media only screen and (max-width:1300px){
    .menu__centerimg{
    display: flex;
    background-image: url("./centerimg.vue") center no-repeat fixed;
    width: 90%;
    height: 350px;
    margin: 0 auto;
}
.menu__centerimg .menu__centerimg-image{
    width: 90%;
    height: 200px;
    display: flex;
    align-self: center;
}   
}

@media only screen and (max-width:1050px){
    .menu__centerimg{
    display: flex;
    background-image: url("./centerimg.vue") center no-repeat fixed;
    width: 90%;
    height: 300px;
    margin: 0 auto;
}
.menu__centerimg .menu__centerimg-image{
    width: 90%;
    height: 150px;
    display: flex;
    align-self: center;
}   
}

@media only screen and (max-width:790px){
    .menu__centerimg{
    display: flex;
    background-image: url("./centerimg.vue") center no-repeat fixed;
    width: 90%;
    height: 250px;
    margin: 0 auto;
}
.menu__centerimg .menu__centerimg-image{
    width: 90%;
    height: 120px;
    display: flex;
    align-self: center;
}   
}
@media only screen and (max-width:650px){
    .menu__centerimg{
    display: flex;
    background-image: url("./centerimg.vue") center no-repeat fixed;
    width: 90%;
    height: 200px;
    margin: 0 auto;
}
.menu__centerimg .menu__centerimg-image{
    width: 90%;
    height: 100px;
    display: flex;
    align-self: center;
}   
}

@media only screen and (max-width:450px){
    .menu__centerimg{
    display: flex;
    background-image: url("./centerimg.vue") center no-repeat fixed;
    width: 90%;
    height: 180px;
    margin: 0 auto;
}
.menu__centerimg .menu__centerimg-image{
    width: 90%;
    height: 90px;
    display: flex;
    align-self: center;
}   
}
@media only screen and (max-width:400px){
    .menu__centerimg{
    display: flex;
    background-image: url("./centerimg.vue") center no-repeat fixed;
    width: 90%;
    height: 160px;
    margin: 0 auto;
}
.menu__centerimg .menu__centerimg-image{
    width: 90%;
    height: 80px;
    display: flex;
    align-self: center;
}   
}

@media only screen and (max-width:350px){
    .menu__centerimg{
    display: flex;
    background-image: url("./centerimg.vue") center no-repeat fixed;
    width: 90%;
    height: 140px;
    margin: 0 auto;
}
.menu__centerimg .menu__centerimg-image{
    width: 90%;
    height: 70px;
    display: flex;
    align-self: center;
}
}

</style>