<template>
    <div class="franchisee__about">
        <p>Brunch and Munch, a popular restaurant located in Golfutaar and Bansbari, Kathmandu, Nepal, has now expanded into a franchise model. With its slogan "every bite is a delight," the franchise aims to offer the same high-quality dining experience to customers across different locations.</p>
        <p>The franchise maintains the same warm ambiance, attentive staff, and customizable menus that have made the original restaurant so popular. Customers can now enjoy the delicious meals.</p>
        <p>Customers can now enjoy the delicious meals and unforgettable dining experience offered by Brunch and Munch in multiple locations across Kathmandu. Whether it's a family gathering or a casual brunch with friends, the franchise provides a perfect venue for any occasion.</p>
    </div>
</template>

<style scoped>
.franchisee__about{
    width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    margin-top: 80px;
    gap: 30px;
}
.franchisee__about p{
    font-weight: 400;
    font-size: 1.25rem;
}
@media only screen and (max-width: 768px) {
    .franchisee__about {
        width: 90%;
        margin-top: 25px;
    }
    .franchisee__about p{ 
        font-size: 1.125rem;
    }
}
@media only screen and (max-width: 480px) {
    .franchisee__about {
        width: 90%;
        margin-top: 20px;
    }
    .franchisee__about p{ 
        font-size: 1rem;
    }
}
@media only screen and (max-width: 414px) {
    .franchisee__about {
        width: 100%;
        margin-top: 20px;
    }
    .franchisee__about p{ 
        font-size: 0.865rem;
    }
}

@media only screen and (max-width: 375px) {
    .franchisee__about {
        width: 100%;
        margin-top: 20px;
    }
    .franchisee__about p{ 
        font-size: 0.865rem;
    }
}
</style>

<script setup></script>