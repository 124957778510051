<template>
    <div class="offer">
        <div class="offer__top">
            <div class="top-content">
                <!-- <p class="showcase">Flavors For Royalty</p> -->
                <h2 class="title">We Offer Top Notch</h2>
                <!-- <p class="subtitle">Come with family & feel the joy of mouthwatering food</p> -->
            </div>
        </div>
        <div class="offer__menu">
            <div class="menu-wrapper" v-for="data in images">
                <div class="imagewrapper">
                    <img :src="'/assets/' + data.imgUrl">
                </div>
                <h3 class="wrapper__title">{{ data.title }}</h3>
                <router-link class="wrapper__desc" to="/menubansbari">{{ data.desc }}</router-link>
            </div>
            <div class="menu-bg"></div>
        </div>
        <img src="assets/offercoffee.png" alt="Coffee Logo" class="coffee">
    </div>
</template>

<script setup>
import { ref } from 'vue';
const images = ref([
    {
        imgUrl: "sandwich.jpg",
        title: "Breakfast",
        desc: "View Menu"
    },
    {
        imgUrl: "coffee.jpg",
        title: "Drinks",
        desc: "View Menu"
    },
    {
        imgUrl: "items.jpg",
        title: "Appetizers",
        desc: "View Menu"
    },
    {
        imgUrl: "sekuwa.jpg",
        title: "Non-Veg",
        desc: "View Menu"
    },
    {
        imgUrl: "butterchicken.png",
        title: "Indian-Dish",
        desc: "View Menu"
    },
])

</script>

<style scoped>
.offer .offer__menu .menu-wrapper .imagewrapper {
    height: 200px;
    width: 250px;
    z-index: 3;
}

.offer .offer__menu .menu-wrapper .imagewrapper .image-offer {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.offer {
    background: rgb(16, 16, 16);
    position: relative;
    backdrop-filter: blur(2px);
    display: flex;
    flex-direction: column;
    gap: 25px;
    height: fit-content;
    justify-content: space-between;
    padding: 10rem 0rem;
}

.offer .offer__top {
    padding: 25px;
}

.offer img {
    height: 259px;
}

.coffee {
    position: absolute;
    left: -18.23px;
    top: -108px;
}

.offer .top-content {
    display: flex;
    flex-direction: column;
}

.offer .offer__menu {
    display: flex;
    justify-content: space-between;
    width: 90%;
    gap: 10px;
    background-size: fill;
    color: #fff;
    margin-bottom: 25px;
    position: relative;
    margin: 0 auto;
    padding: 25px 0;
    text-align: center;
}

.offer .offer__menu img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    z-index: 3;
}

.offer .offer__menu .wrapper__title {
    font-weight: 400;
    font-size: 24px;
}

.offer .offer__menu .wrapper__desc {
    font-weight: 400;
    font-size: 20px;
    color: var(--yellow);
}

.offer .offer__menu .menu-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
}

.title {
    margin-top: 10px;
    font-size: 50px;
}

.menu-bg {
    position: absolute;
    top: 0;
    min-width: 100 !important;
    min-height: 100%;
    left: 0px;
    z-index: 1;
    filter: blur(1px);
    margin: 0 auto;
    background: url("@/assets/brickwall.svg") repeat fixed center;
}

@media only screen and (max-width:1440px) {
    .offer {
        flex-wrap: wrap;
    }

    .offer .offer__menu .menu-wrapper .imagewrapper {
        height: 180px;
        width: 180px;
    }

    .offer .imagewrapper img {
        height: 180px;
    }
}

@media only screen and (max-width:1048px) {
    .offer .offer__menu .menu-wrapper .imagewrapper {
        height: 130px;
        width: 130px;
    }

    .offer .imagewrapper img {
        height: 130px;
    }

    .menu-bg {
        min-width: 100% ;
        min-height: 50%;
    }
}

@media only screen and (max-width:750px) {
    .offer {
        position: unset;
        min-width: 100%;
    }

    .offer .offer__menu {
        flex-wrap: wrap;
        gap: 60px;
    }

    .offer .offer__menu .menu-wrapper {
        width: 100%;
        aspect-ratio: 1.5;
    }

    .offer .offer__menu .menu-wrapper .imagewrapper {
        height: 100%;
        width: 100%;
    }

    .offer .imagewrapper img {
        height: 100%;
    }

    .menu-bg {
        min-width: 100%;
        min-height: 50%;
    }
}</style>
