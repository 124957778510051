<template>
    <div class="homepage__menu">
        <div class="homepage__menu-header">
            <p class="desc">Special Selection</p>
            <h2>Our Menu</h2>
        </div>
        <div class="homepage__menu-content">
            <div class="homepage__menu-container" v-for="(item, index) in data">
                <h2 class="menu-title">{{ index }}</h2>
                <div class="menu-container__wrapper">
                    <div class="menu-contents" v-for="i in item">
                        <div class="right">
                            <div class="right__name">
                                <h3>{{ i.name }}</h3>
                                <p class="colon">&#8212;</p>
                                <p class="price">{{ i.price }}</p>
                            </div>
                            <p class="descript">{{ i.desc }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="coffeeimgcontainer">
            <img src="@/assets/offercoffee.svg" alt="Coffee Logo" class="coffee">
        </div>
        <div class="homepage__menu-footer">
                <router-link to="/menubansbari" class="menu-link">
                    View All
                </router-link>
        </div>
    </div>    
</template>

<style scoped>
@import "@/css/menu.css";

</style>

<script setup>
import { ref } from 'vue';

const data = ref({
    "coffee": [
        {
            name: "Espresso (Single/Double)",
            img: "https://picsum.photos/id/237/150/150",
            price: "Rs. 120/140",
            desc: "Espresso is a type of coffee that is made by forcing hot water through finely ground coffee beans. The terms single and double are used to refer to the amount of coffee used to make the espresso shot."
        },
        {
            name: "Espresso Macchiato (Single/Double)",
            img: "https://picsum.photos/seed/picsum/150/150",
            price: "Rs. 130/150",
            desc: "An Espresso Macchiato is an espresso drink that consists of a single or double shot of espresso topped with a small amount of steamed milk and foam, which is often marked (macchiato means marked in Italian) with a dollop of foam or a swirl of caramel or chocolate sauce."
        },
        {
            name: "Americano (Single/Double)",
            img: "https://picsum.photos/seed/picsum/150/150",
            price: "Rs. 140/160",
            desc: "An Americano is a type of coffee drink that is made by adding hot water to a shot or two of espresso. The resulting drink has a similar strength to drip coffee, but with a different flavor profile."
        },
        {
            name: "Espresso Affogato",
            img: "https://picsum.photos/seed/picsum/150/150",
            price: "Rs. 170",
            desc: "An Espresso Affogato is a delicious Italian dessert that consists of a scoop of vanilla ice cream drowned in a shot of hot espresso. The word affogato means drowned in Italian"
        },
        {
            name: "Cafe Mocha (Single/Double)",
            img: "https://picsum.photos/seed/picsum/150/150",
            price: "Rs. 200",
            desc: "For a rich, chocolaty flavor, espresso, chocolate syrup or powder, and steamed milk are combined to make a cafe mocha. Depending on the quantity of the drink being produced and personal desire, different amounts of espresso can be used to make a Cafe Mocha."
        },
        {
            name: "Caramel Macchiato (Single/Double)",
            img: "https://picsum.photos/seed/picsum/150/150",
            price: "Rs. 220",
            desc: "A Caramel Macchiato is a type of coffee drink that combines espresso, steamed milk, vanilla syrup, and caramel sauce to create a sweet and creamy flavor."
        },
        {
            name: "Mochaccino (Single/Double)",
            img: "https://picsum.photos/seed/picsum/150/150",
            price: "Rs. 200",
            desc: "A Mochaccino, also known as a mocha, is a type of coffee drink that combines espresso, chocolate syrup or powder, and steamed milk to create a rich and chocolatey flavor."
        },
        {
            name: "Honey/Vanilla Latte (Single/Double)",
            img: "https://picsum.photos/seed/picsum/150/150",
            price: "Rs. 200",
            desc: "A Honey or Vanilla Latte is a type of coffee drink that combines espresso, steamed milk, and honey or vanilla syrup to create a sweet and creamy flavor."
        },
        {
            name: "Cafe Latte (Single/Double)",
            img: "https://picsum.photos/seed/picsum/150/150",
            price: "Rs. 160",
            desc: "A Cafe Latte is a type of coffee drink that combines espresso and steamed milk to create a smooth and creamy flavor."
        },
        {
            name: "Cappuccino (Single/Double)",
            img: "https://picsum.photos/seed/picsum/150/150",
            price: "Rs. 160",
            desc: "Cappuccino is an Italian coffee made with espresso, steamed milk, and frothed milk in equal parts. The drink is typically served in a small porcelain cup and may be topped with cocoa powder or cinnamon."
        },
    ],
    
    "biryani": [
        {
            name: "Veg Biryani",
            desc: "Vegetable biryani is a vegetarian version of the popular South Asian rice dish, made with spiced rice, mixed vegetables, and aromatic herbs.",
            img: "https://picsum.photos/seed/picsum/150/150",
            price: "Rs. 250",
        },
        {
            name: "Chicken Biryani",
            desc: "Chicken biryani is a popular South Asian rice dish made with spiced rice and marinated chicken, cooked together with a variety of herbs and spices.",
            img: "https://picsum.photos/seed/picsum/150/150",
            price: "Rs. 300",
        },
        {
            name: "Egg Biryani",
            desc: "Egg biryani is a South Asian rice dish made with spiced rice and boiled eggs, mixed with a variety of herbs and spices for a delicious and satisfying meal.",
            img: "https://picsum.photos/seed/picsum/150/150",
            price: "Rs. 280",
        }
    ]
})
</script>
