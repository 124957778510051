import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/homepage'
import Franchise from "../views/franchise"
// import Event from "../views/event"
import Gallery from "../views/gallery"
import Menu from "../views/menubansbari"
import MenuGolfutaar from "../views/menugolfutaar"

const routes = [
  {
    path: '/',
    name: 'homepage',
    component: HomePage,
  },
  {
    path: '/franchise',
    name: 'franchise',
    component: Franchise
  },
  // {
  //   path: '/events',
  //   name: 'event',
  //   component: Event
  // },
  {
    path: '/gallery',
    name: 'gallery',
    component: Gallery
  },
  {
    path: '/menubansbari',
    name: 'menubansbari',
    component: Menu
  },
  {
    path: '/menugolfutaar',
    name: 'menugolfutaar',
    component: MenuGolfutaar
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
