<template>
    <div class="book">
        <div class="book__left">
            <p>Book Through Call</p>
            <p><a href="tel:+9779803478432" class="phone">+9779803478432</a></p>
        </div>
        <div class="book__right">
            <p class="right-title cont">Contact Us</p>
            <p class="right-title">For Event & More</p>
        </div>
        <div class="book__mask"></div>
    </div>
</template>

<style scoped>
@import "@/css/common.css";

.book{
    display: flex;
    justify-content: space-between;
    background: url("@/assets/restaurant-photos/IMG_2925.jpeg") no-repeat center;
    border-radius: 10px;
    width: 90%;
    height: 150px;
    padding: 40px;
    margin: 0 auto;
    background-size: cover;
    color: #fff;
    align-items: center;
    position: relative;
    z-index: 5;
    margin: 0 auto 100px auto;

}
.book .book__left{
    padding: 0 90px;
    font-size: 22px;
    z-index: 5;
}
.book .book__right{
    padding: 0 90px;
    font-size: 22px;
    z-index: 5;
}
.book .book__right .right-title {
    font-family: 'Marcellus';
    font-weight: 400;
    font-size: 30px;
    z-index: 5;
}
.book .book__right .cont {
    text-align: right;
}
.phone{
    color:var(--yellow);
    z-index: 5;
}
.book .book__mask {
    background-color: #1717175e;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 1;
    border-radius: 10px;
}

@media only screen and (max-width: 1230px){
    .book{
        height: 120px;
        padding: 0;
        width: 80%;
        margin: 0 auto 30px auto;
    }

    .book .book__left{
        text-align: center;
        font-size: 1rem;
        z-index: 5;
    }
    .book .book__right{
        padding: 0 20px;
        font-size: 1.125rem;
    }
.book .book__right .right-title {
        font-family: 'Marcellus';
        font-weight: 400;
        font-size: 1.25rem;
    }
}
@media only screen and (max-width: 745px){
    .book{
        height: 90px;
        width: 80%;
        margin: 0 auto 30px auto;
    }

    .book .book__left{
        font-size: 0.8rem;
    }
    .book .book__right{
        font-size: 1rem;

    }
.book .book__right .right-title {
        font-family: 'Marcellus';
        font-weight: 400;
        font-size: 1rem;
    }
}
@media only screen and (max-width: 640px){
    .book{
        height: 75px;
        width: 80%;
        margin: 20px auto;
        justify-self: center;
    }

    .book .book__left{
        font-size: 0.7rem;
    }
    .book .book__right{
        display: flex;
        flex-direction: column;
    }
}
@media only screen and (max-width: 400px){
    .book{
        height: 65px;
        width: 80%;
        margin: 0 auto 20px auto;
    }
    .book .book__left{
        font-size: 0.6rem;
    }
    .book .book__right{
        display: none;
    }
}
</style>

<script setup>
    
</script>
