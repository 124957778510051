<template>
    <div class="franchise__header">
        <h1 class="franchise__title"> Franchise </h1>
        <div class="franchise_mask"></div>
    </div>
</template>

<style scoped>
@import "@/css/common.css";

.franchise__header {
    width: 100%;
    height: 300px;
    background: url("@/assets/franchiseheader.png") no-repeat;
    position: relative;
    background-size: cover;
}

.franchise__title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-family: 'Marcellus';
    font-style: normal;
    font-weight: 400;
    font-size: 3.75rem;
    position: relative;
    color: var(--yellow);
    z-index: 5;
}
.franchise_mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    filter: blur(3px);
    background-color: #00000070;
}

@media only screen and (max-width: 1024px) {
    .franchise__header {
        height: 190px;
    }

    .franchise__title {
        font-size: 2.5rem;
    }
}

@media only screen and (max-width: 768px) {
    .franchise__header {
        height: 160px;
    }

    .franchise__title {
        font-size: 2.5rem;
    }
}

@media only screen and (max-width: 480px) {
    .franchise__header {
        height: 160px;
    }

    .franchise__title {
        font-size: 2.5rem;
    }
}

@media only screen and (max-width: 414px) {
    .franchise__header {
        height: 150px;
    }

    .franchise__title {
        font-size: 2.5rem;
    }
}

@media only screen and (max-width: 375px) {
    .franchise__header {
        height: 140px;
    }

    .franchise__title {
        font-size: 2.5rem;
    }
}
</style>